import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { Alert, Divider, TextField, Typography } from '@mui/material';
import AlertD from '../util/AlertD';
import { getId, numberWithCommas } from '../util/ID'
import InputAdornment from '@mui/material/InputAdornment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import SearchCompany from '../love/SearchCompany'

export default function BuyDialog2(props) {
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [open, setOpen] = React.useState(false);

    const [createDate, setCreateDate] = React.useState(dayjs(new Date()));
    const [dir, setDirection] = React.useState("0");
    const [sid, setSID] = React.useState(null);
    const [system, setSystem] = React.useState("0");

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    // sn : String, id : String, ymd : String, dir : Int = 0, setting : TurtleLoveSetting
    const handleOKClose = () => {
        let param = {
            sn: getId(),
            sid: sid,
            ymd: dayjs(createDate).format('YYYYMMDD'),
            dir: dir,
            system: system
        }

        if (localStorage.getItem("turtle_lic") != null) {
            param['lic'] = localStorage.getItem("turtle_lic")
        }

        fetch("/turtle/v1/love/click", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.text())
            .then(
                result => {
                    if (result === "0") {
                        setOpen(false);
                        if (props.close != null) props.close(false)
                        if (props.refresh != null) props.refresh()
                    }
                }
            );
    };


    useEffect(() => {
        if (props.open) {           
            setOpen(true)
        } else setOpen(false)
    }, [props.open]);

    const direction = [
        {
            value: "0",
            label: "多方",
        },
        {
            value: '1',
            label: "空方",
        }
    ];

    const systemMenu = [
        {
            value: "0",
            label: "系統Ⅰ",
        },
        {
            value: '1',
            label: "系統Ⅱ",
        }
    ];

    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    新增交易
                </DialogTitle>
                {/* <Divider></Divider> */}

                <DialogContent sx={{ p: 3 }}>
                
                    <Stack spacing={3} direction="column" sx={{ mt: 2 }}>

                        <SearchCompany setSID={setSID} />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={createDate}
                                onChange={(newValue) => setCreateDate(newValue)}
                                label={'交易日期'} format='YYYY-MM-DD' />
                        </LocalizationProvider>

                        <TextField
                            size="medium"
                            id="system"
                            select
                            label="系統"
                            value={system}
                            onChange={event => setSystem(event.target.value)}
                        >
                            {systemMenu.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            size="medium"
                            id="A"
                            select
                            label="多空"
                            value={dir}
                            onChange={event => setDirection(event.target.value)}
                        >
                            {direction.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Stack>
                    
                </DialogContent>
                <DialogActions>
                    <Button sx={{ width: 80 }} onClick={handleClose}>取消</Button>
                    <Button sx={{ width: 80 }} onClick={handleOKClose} autoFocus>
                        確定
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
