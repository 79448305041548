import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { Alert, Divider, TextField, Typography } from '@mui/material';
import AlertD from '../util/AlertD';
import { getId, numberWithCommas } from '../util/ID'
import InputAdornment from '@mui/material/InputAdornment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';

export default function LoveSetting(props) {
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [open, setOpen] = React.useState(false);

    const [capital, setCapital] = React.useState(localStorage.getItem("turtle_capital") != null ? localStorage.getItem("turtle_capital") : 1000000);
    const [risk, setRisk] = React.useState(localStorage.getItem("turtle_risk") != null ? localStorage.getItem("turtle_risk") : 1);
    const [atr, setATR] = React.useState(localStorage.getItem("turtle_atr") != null ? localStorage.getItem("turtle_atr") : 20);

    const [createDate, setCreateDate] = React.useState(null);
    const [dir, setDirection] = React.useState("0");
    const [system, setSystem] = React.useState("0");

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    // sn : String, id : String, ymd : String, dir : Int = 0, setting : TurtleLoveSetting
    const handleOKClose = () => {
        let param = {
            sn: getId(),
            id: props.row.love.id,
            ymd: dayjs(createDate).format('YYYYMMDD'),
            dir: dir,
            system: system,
            setting: {
                book_value: capital,
                book_percent: risk,
                atr: atr
            }

        }

        if (localStorage.getItem("turtle_lic") != null) {
            param['lic'] = localStorage.getItem("turtle_lic")
        }

        fetch("/turtle/v1/love/setting", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.text())
            .then(
                result => {
                    if (result === "0") {
                        setOpen(false);
                        if (props.close != null) props.close(false)
                        if (props.refresh != null) props.refresh()
                    }
                }
            );
    };


    useEffect(() => {
        if (props.open) {
            if (props.row != null && props.row.love != null) {
                setCreateDate(dayjs(props.row.love.ymd))
                setDirection(props.row.love.dir)

                if (props.row.love.setting != null) {
                    setCapital(props.row.love.setting.book_value)
                    setRisk(props.row.love.setting.book_percent)
                    setATR(props.row.love.setting.atr)
                }
            }
            setOpen(true)
        } else setOpen(false)
    }, [props.open]);

    const direction = [
        {
            value: "0",
            label: "多方",
        },
        {
            value: '1',
            label: "空方",
        }
    ];

    const systemMenu = [
        {
            value: "0",
            label: "系統Ⅰ",
        },
        {
            value: '1',
            label: "系統Ⅱ",
        }
    ];

    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    設定
                </DialogTitle>
                {/* <Divider></Divider> */}

                <DialogContent sx={{ p: 3 }}>
                    <Typography fontWeight={"bold"} variant='body1' sx={{ mt: 0 }}>交易</Typography>
                    <Stack spacing={3} direction="column" sx={{ mt: 2 }}>


                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={createDate}
                                onChange={(newValue) => setCreateDate(newValue)}
                                label="交易日期" format='YYYY-MM-DD' />
                        </LocalizationProvider>

                        <TextField
                            size="medium"
                            id="system"
                            select
                            label="系統"
                            value={system}
                            onChange={event => setSystem(event.target.value)}
                        >
                            {systemMenu.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            size="medium"
                            id="A"
                            select
                            label="多空"
                            value={dir}
                            onChange={event => setDirection(event.target.value)}
                        >
                            {direction.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Stack>
                    <Typography fontWeight={"bold"} variant='body1' sx={{ mt: 5 }}>資金配置</Typography>
                    <Stack spacing={3} direction="column" sx={{ mt: 2 }}>
                        <TextField
                            value={capital}
                            onChange={event => setCapital(event.target.value)}
                            inputProps={{}}
                            size="medium" label="名目帳戶" fullWidth InputProps={{
                                endAdornment: <InputAdornment position="end">元</InputAdornment>,
                            }}></TextField>
                        <TextField
                            value={risk}
                            onChange={event => setRisk(event.target.value)}
                            size="medium" label="帳戶%" fullWidth
                            helperText="每單位價值。帳戶的多少百分比。預設：1%"
                            inputProps={{}}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}></TextField>

                        <TextField size="medium" label="ATR" fullWidth
                            value={atr}
                            onChange={event => setATR(event.target.value)}
                            helperText="平均真實區間。預設20日"
                            inputProps={{}}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">日</InputAdornment>,
                            }}
                        ></TextField>

                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ width: 80 }} onClick={handleClose}>取消</Button>
                    <Button disabled={localStorage.getItem("noad") != "1"}  sx={{ width: 80 }} onClick={handleOKClose} autoFocus>
                        確定
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
