import React from 'react'
import { BrowserRouter as Router, Route, HashRouter, Routes } from 'react-router-dom'
import CHome from './chome/CHome'

export default props => (
  <HashRouter>
    <Routes>
      <Route exact path='/' element={<CHome  path={"home"} />} />
      <Route exact path='/ghome' element={<CHome  path={"ghome"}/>} />
      <Route exact path='/ioshome' element={<CHome path={"ioshome"}/>} />
      <Route exact path='/open' element={ <CHome  path={"home"} /> } />  
    </Routes>
  </HashRouter>
)