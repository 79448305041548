import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import AlertD from '../util/AlertD';

export default function LicenseCheckDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [name, setName] = React.useState(null);
    const [phone, setPhone] = React.useState(null)



    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    const handleOKClose = () => {
        bindLic()
    };

    const bindLic = () => {
        if (localStorage.getItem("turtle_lic") != null) {
            let lic = localStorage.getItem("turtle_lic")
            let param = {
                lic: lic,
                phone: phone,
                name: name
            }

            fetch("/turtle/v1/payment/addMapping", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result.status === 0) {
                            setOpen(false);
                            setAlertMsg("綁定成功")
                            setShowAlert(true)
                            if (props.close != null) props.close(false)
                        } else {
                            setAlertMsg("此序號已綁定")
                            setShowAlert(true)
                        }
                    },
                    error => {
                        setAlertMsg("綁定序號失敗")
                        setShowAlert(true)
                    }
                );
        } else {
            setAlertMsg("請確定序號存在")
            setShowAlert(true)
        }
    }

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);


    useEffect(() => {
    }, []);

    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"序號綁定"}
                </DialogTitle>
                <DialogContent>

                    <Stack spacing={3} direction="column" sx={{ p: 2 }}>
                        <TextField
                            fullWidth
                            id="name"
                            label="姓名"
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />

                        <TextField
                            fullWidth
                            id="phone"
                            label="手機後五碼"
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    <Button onClick={handleOKClose} autoFocus>
                        確定
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
