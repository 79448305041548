import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { getId, numberWithCommas } from '../util/ID'

echarts.use(
    [VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent, BarChart]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        // width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));



export default function StockHistoryDialog(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);
    const [loading, setLoading] = React.useState(false)

    const [open, setOpen] = React.useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);

    function genoption1() {
        let k = {
            animation: false,
            tooltip:{
                show:true
            },
            legend: {
                show: true,
                bottom: 0
            },
            title: [
                {
                    top: 35,
                    left: 'center',
                    text: "90日動能排序(全部股票)",
                    textStyle:{
                        fontSize: 22
                    }
                },
            ],
            grid: [{
                top: 60,
                left: 0,
                right: 40,
                bottom: 30,
                containLabel: true
            }],
            axisPointer:{
                show: true
            },
            xAxis: [{
                type: 'category',
                boundaryGap: false,
                data: result.map(row => row.d),
            },{
                type: 'category',
                boundaryGap: false,
                data: result.map(row => row.d),
                show: false
            }],
            yAxis: [{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                inverse : true
            },{
                scale: true,
                type: 'value',
                position: 'right',
                show: true,
            }],
            dataZoom: [
                {
                    type: 'inside',
                    xAxisIndex: 'all',
                    start: fullScreen ? 75 : 0,
                    end: 100
                }
            ],
            series: [
                {
                    name: "動能排名",
                    type: 'line',
                    data: result.map(row => row.r),
                    smooth: true,
                    endLabel: {
                        show: true
                    },
                    lineStyle: {
                        width: 3
                    },
                    showSymbol: false
                },
                {
                    name: "股價",
                    type: 'line',
                    data: result.map(row => row.p),
                    smooth: true,
                    endLabel: {
                        show: true
                    },
                    lineStyle: {
                        width: 3
                    },
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    showSymbol: false
                }
            ]
        };
        return k;
    }

    const getAvg = () => {
        return localStorage.getItem("mv_avg") != null ? localStorage.getItem("mv_avg") : 100
    }

    const getATR = () => {
        return localStorage.getItem("turtle_atr") != null ? localStorage.getItem("turtle_atr") : 20
    }

    const getCapital = () => {
        return localStorage.getItem("turtle_capital") != null ? localStorage.getItem("turtle_capital") : 300000
    }

    const getMC = () => {
        return localStorage.getItem("mv_mc") != null ? localStorage.getItem("mv_mc") : 200
    }

    const getCp = () => {
        return localStorage.getItem("mv_cp") != null ? localStorage.getItem("mv_cp") : 0
    }

    const getMD = () => {
        return localStorage.getItem("mv_md") != null ? localStorage.getItem("mv_md") : 90
    }

    const fetchData = () => {
        setLoading(true)

        let risk = localStorage.getItem("turtle_risk") != null ? localStorage.getItem("turtle_risk") : 0.1
        let gap = localStorage.getItem("mv_gap") != null ? localStorage.getItem("mv_gap") : 15


        let param = {
            sid : props.sid,
            capital: getCapital(),
            risk: parseFloat(risk) / 100,
            gap: gap,
            avg: getAvg(),
            atr_days: getATR(),
            mc: getMC(),
            sn: getId(),
            cp : getCp(),
            md: getMD()
        }

        if (localStorage.getItem("turtle_lic") != null) {
            param['lic'] = localStorage.getItem("turtle_lic")
        }

        if (localStorage.getItem("mv_mp") != null) {
            param['mp'] = localStorage.getItem("mv_mp")
        }

        fetch("/move/v1/stock/history", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setLoading(false)

                    if (result != null) {
                        if (result.data != null && result.data.length > 0) {
                            setResult(result.data)
                        } 
                    }
                }
            );
    }

    useEffect(() => {
        if (props.sid != null) fetchData()
    }, []);

    useEffect(() => {
        if (result != null) setOption(genoption1)
    }, [result]);

    const getColor = () => {
        if (result != null && result.price != null) {
            if (result.price.i > 0) return "red"
            else if (result.price.i < 0) return "green"
            else return null
        } else return null
    }

    const getInv = () => {
        if (result != null && result.price != null) {
            if (result.price.i > 0) return "▲" + result.price.i + '元'
            else if (result.price.i < 0) return "▼" + result.price.i + '元'
            else return result.price.i + '元'
        } else if (result.price != null)
            return result.price.i + '元'
        else return '▲0元'
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
        >
            <DialogTitle sx={{ p: 0 }}>
            </DialogTitle>
            <DialogContent sx={{pl: 1, pr: 1}}>
                {/* {result != null && <Box display={'flex'}>
                    <Stack direction='row' spacing={0.5} sx={{ flex: 1, p: 0, pl: 0, pt: 0.5, alignSelf: 'center' }} >
                        <Typography sx={{ fontSize: 30, fontWeight: 'bold', alignSelf: 'center' }} >{result.company.short_name}</Typography>
                        <Typography variant="caption" sx={{ fontWeight: 'normal' }} >{result.stock}</Typography>
                    </Stack>
                    <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }} >
                        <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{result.price != null ? result.price.p : "-"}</Typography>
                        <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{getInv()}</Typography>
                    </Stack>
                </Box>}

                <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                    style={{
                        border: "none",
                        height: 0.5,
                        margin: 0,
                    }} /> */}

                {option != null ?
                    <ReactEChartsCore
                        theme={theme.palette.mode}
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={false}
                        style={{ height: '80vh' }}
                    // onEvents={onEvents}
                    />
                    : null}
            </DialogContent>
            <DialogActions>
                <Button disableElevation variant="contained" color="info" onClick={handleClose}>關閉</Button>
            </DialogActions>
        </Dialog>
    );
}

