import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function SearchCompany(props) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  const oninput = (event, value, reason) => {
    if ((isNumeric(value) && value.length > 1) || (!isNumeric(value) && value.length > 0)) {
      fetch("/turtle/v1/info/searchcom?key=" + value)
        .then(res => res.json())
        .then(
          result => {
            setOptions(result);
          });
    }
  }

  const onclose = (event, reason) => {
    setOpen(false);
  }

  const onchange = (event, value) => {

    if (value != null) {
      if (props.setSID != null) props.setSID(value.value)
      //   props.menuChange(value.value)
    } else {
      if (props.setSID != null) props.setSID(null)
    }
  }


  return (

    // <Autocomplete
    //       id="asynchronous-demo"
    //       sx={{ width: 300 }}
    //       open={open}
    //       onOpen={() => {
    //         setOpen(true);
    //       }}
    //       onClose={() => {
    //         setOpen(false);
    //       }}
    //       isOptionEqualToValue={(option, value) => option.title === value.title}
    //       getOptionLabel={(option) => option.title}
    //       options={options}
    //       loading={loading}
    //       renderInput={(params) => (
    //         <TextField
    //           {...params}
    //           label="Asynchronous"
    //           InputProps={{
    //             ...params.InputProps,
    //             endAdornment: (
    //               <React.Fragment>
    //                 {loading ? <CircularProgress color="inherit" size={20} /> : null}
    //                 {params.InputProps.endAdornment}
    //               </React.Fragment>
    //             ),
    //           }}
    //         />
    //       )}
    //     />


    <Autocomplete
      id="stock input"
      open={open}
      onInputChange={oninput}
      onOpen={() => {
        setOpen(true);
      }}
      freeSolo
      autoHighlight
      onChange={onchange}
      onClose={onclose}
      // getOptionSelected={(option, value) => option.name === value.name}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={option => option.label}
      options={options}
      noOptionsText=""
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          label="股票代號或名稱"
          InputProps={{
            ...params.InputProps,
          }}

        />
      )}
      

    />
  );
}

